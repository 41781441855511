<template>
    <div>
        <div class="wz">
            <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>学校管理</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="box">
            <div class="search" v-if="roleId==1">
                <div class="searchleft">
                    <el-form :inline="true" :model="searchfrom" class="demo-form-inline">
                        <el-form-item label="标题">
                            <el-input v-model="searchfrom.schoolName" placeholder="标题"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-search" @click="onSubmit" >查询</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="searchright"><el-button  type="primary" icon="el-icon-circle-plus-outline" @click="openadd">添加学校</el-button></div>
            </div>
            <div class="list" v-loading="loading">
                <el-table
                :data="list"
                border
                :header-cell-style="{'font-weight':'bold','color':'#555555','padding':'6px 0'}"
                stripe
                style="width: 100%">
                    <el-table-column
                    prop="id"
                    width="100"
                    label="Logo">
                        <template slot-scope="scope">
                            <img :src="scope.row.schoolLogo" width="60" alt="">
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="schoolName"
                    label="标题">
                    </el-table-column>
                    <el-table-column
                    prop="account"
                    label="管理账号">
                    </el-table-column>
                    <el-table-column
                    prop="sort"
                    label="排序">
                    </el-table-column>
                    <el-table-column
                    prop="sort"
                    label="是否为高校">
                        <template slot-scope="scope">
                            <el-tag type="primary" v-if="scope.row.isHighSchool">是</el-tag>
                            <el-tag type="warning" v-if="!scope.row.isHighSchool">否</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="schoolWeb"
                    label="官网地址">
                    </el-table-column>
                    <el-table-column
                    prop="address"
                    width="200"
                    label="操作">
                        <template slot-scope="scope">
                            <el-button type="primary"   @click="openedit(scope.row)">编辑</el-button>
                            <el-button type="danger" v-if="roleId==1"  @click="delitem(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <app-page :total="total"  :current="pageNo" :pageSize="pageSize" @handleCurrentChange="currentchange" @handleSizeChange="handchange"></app-page>
        </div>
        <pop-up :title="status==1?'添加学校':'编辑学校'" width="40%" :isshow="showpop" :haveconfirm="true" @close="getclose" @confirm="getconfirm">
            <div slot="box">
                <el-form
                    label-width="120px"
                >
                    <el-form-item label="学校名称" prop="name">
                    <el-input v-model="form.schoolName"></el-input>
                    </el-form-item>
                    <el-form-item label="Logo" prop="name">
                    <upload-img :modeldata="form" @changeurl="getimgurl" style="margin-top:15px;"></upload-img>
                    </el-form-item>
                    <el-form-item label="官网地址" prop="page">
                    <el-input v-model="form.schoolWeb"></el-input>
                    </el-form-item>
                    <el-form-item label="高校管理员账号" v-if="status==1" prop="page">
                    <el-input v-model="form.sort"></el-input>
                    </el-form-item>
                    <el-form-item label="排序"  prop="page">
                    <el-input-number v-model="form.sort"  :min="1" :max="1000" label="描述文字"></el-input-number>
                    </el-form-item>
                    <el-form-item label="是否为高校"  prop="page">
                        <el-radio v-model="form.isHighSchool" :label="1">是</el-radio>
                        <el-radio v-model="form.isHighSchool" :label="0">否</el-radio>
                    </el-form-item>
                    <el-form-item label="描述" prop="page">
                    <el-input
                        type="textarea"
                        :autosize="{ minRows: 2, maxRows: 6}"
                        placeholder="请输入内容"
                        v-model="form.schoolDescribe">
                        </el-input>
                        <!-- <tinymce v-model="form.schoolDescribe" :height="400" /> -->
                    </el-form-item>
                </el-form>
            </div>
        </pop-up>
    </div>
</template>
<script>
import Tinymce from "@/components/Tinymce";
import serverurl from '@/api/booth'
import _api from '@/api/index'
export default {
    components:{
        Tinymce,
    },
    data(){
        return{
            roleId:localStorage.getItem("roleId"),
            schoolId:JSON.parse(localStorage.getItem("schoolId")),
            loading:false,
            status:1,
            total:0,
            pageSize:10,
            pageNo:1,
            list:[],
            form:{id:null,schoolName:null,schoolLogo:null,sort:1,isHighSchool:1,schoolDescribe:null,schoolWeb:null,url:null,account:null},
            showpop:false,
            searchfrom:{
                schoolName:null
            }
        }
    },
    created(){
        this.getList()
    },
    methods:{
        getList(){
            this.loading=true
            _api.get(serverurl.school,{pageNo:this.pageNo,pageSize:this.pageSize,schoolName:this.searchfrom.schoolName,id:this.schoolId}).then(res=>{
                this.loading=false
                console.log(res)
                if(res.code==200){
                    this.list=res.data.list
                    this.total=res.data.total
                }
            })
        },
        onSubmit(){
            this.pageNo=1
            this.getList()
        },
        delitem(row){
            this.$confirm('真的确定要删除吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                _api.delete(serverurl.school,row.id).then(res=>{
                    console.log(res)
                    if(res.success){
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.getList()
                    }
                })
                
            }).catch(() => {        
            });
        },
        getimgurl(){

        },
        openedit(row){
            this.status=2
            for(let k in this.form){
                this.form[k]=row[k]
            }
            this.form.url=this.form.schoolLogo
            if(this.form.isHighSchool==true){
                this.form.isHighSchool=1
            }else{
                this.form.isHighSchool=0
            }
            this.showpop=true
        },
        openadd(){
            
            this.showpop=true
            
        },
        getclose(){
            this.showpop=false
            this.form={id:null,schoolName:null,schoolLogo:null,schoolDescribe:null,schoolWeb:null,url:null,isHighSchool:1,}
            this.status=1
        },
        getconfirm(){
            let apitype=this.status==1?'post':'put'
            this.form.schoolLogo=this.form.url
            _api[apitype](serverurl.school,this.form).then(res=>{
                if(res.code==200){
                    this.$message.success("操作成功")
                    this.getclose()
                    this.getList()
                }else{
                    this.$message.error(res.message)
                }
            })
        },
        handchange(data){//分页Size变化
            this.pageSize=data
            this.pageNo=1
            this.getList()
        },
        currentchange(data){//当前页变化
            // console.log(data)
            this.pageNo=data
            this.getList()
        },
    }
}
</script>